import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const BackgroundPage = () => (
  <Layout backgroundTab={ "Background" }>
    <section className="content__body--mybackground">
      <div className="education">
          <h1>During my studies</h1>
          <p>
              Most of my years studying engineering I spent them in Malaga, Spain at <a href="http://www.uma.es/?set_language=en">Universidad de Malaga</a>, but also I spend one of my years in Oulu, Finland at <a href="http://www.oulu.fi/university/">Oulun Yliopisto</a>. Some of the projects that I developed are the following:
          </p>
          <ul>
              <li><Link to="/dissertation/"> Support Computer System for Brain Aneurysm Diagnosis with image recognition </Link>[Spanish only]</li>
              <li>Augmented reality project for interacting with museum exhibitions</li>
              <li>Android app based on the <a href="https://en.wikipedia.org/wiki/Gymkhana">Gymkhana game</a>, using QR and an embedded chat</li>
              <li>Breast cancer prediction project using DNA micro-arrays and neuronal networks</li>
          </ul>
      </div>
      <div className="interest">
          <h1>Some of my interests !!!</h1>
          <p>
              I love technology and probably a lot of my interests are related with it, but also there are areas which I'm into too. Some of them are:
          </p>
          <ul>
              <li>Medical engineering</li>
              <li>Biotechonolgies</li>
              <li>Augmented reality</li>
              <li>Image processing</li>
              <li>Artificial Intelligence</li>
              <li>Music</li>
              <li>Traveling</li>
              <li>Cars</li>
              <li>Technology</li>
              <li>Medicine</li>
              <li>Computer animation</li>
              <li>Mobile</li>
              <li>Machine learning</li>
              <li>Data mining</li>
              <li>Large scale systems</li>
          </ul>
      </div>
    </section>
  </Layout>
)

export default BackgroundPage


